import * as React from 'react'
import * as SeparatorPrimitive from '@radix-ui/react-separator'
import cn from '@/lib/util/cn'
import { cva, type VariantProps } from 'class-variance-authority'

export const separatorVariants = cva('shrink-1 relative flex-grow', {
  variants: {
    variant: {
      default: 'bg-border',
      light: 'bg-gray-400'
    }
  }
})

export interface SeparatorProps
  extends React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>,
    VariantProps<typeof separatorVariants> {}

const Separator = React.forwardRef<React.ElementRef<typeof SeparatorPrimitive.Root>, SeparatorProps>(
  ({ className, orientation = 'horizontal', decorative = true, variant = 'default', children, ...props }, ref) =>
    <div
      className={cn(
        'relative flex flex-nowrap items-center justify-center',
        orientation === 'horizontal' ? 'flex-row' : 'h-full flex-col',
        className
      )}
      >
      <SeparatorPrimitive.Root
        ref={ref}
        decorative={decorative}
        orientation={orientation}
        className={cn(separatorVariants({ variant }), orientation === 'horizontal' ? 'h-[1px]' : 'w-[1px]')}
        {...props}
      />
      {children &&
        <div className={cn('flex-shrink text-gray-400', orientation === 'horizontal' ? 'mx-4' : 'my-1')}>
          {children}
        </div>
      }
      <SeparatorPrimitive.Root
        ref={ref}
        decorative={decorative}
        orientation={orientation}
        className={cn(separatorVariants({ variant }), orientation === 'horizontal' ? 'h-[1px]' : 'w-[1px]')}
        {...props}
      />
    </div>

)
Separator.displayName = SeparatorPrimitive.Root.displayName

export default Separator
